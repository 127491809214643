import * as React from 'react';
import {graphql} from 'gatsby';
import EventDaysList from '../../components/agenda/EventDaysList';
import {Speech, SpeechDAO} from '../../models/Speech';
import IndiaBasePageLayout from '../../layouts/IndiaBasePageLayout';
import {Section} from '../../components/Section';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface ProceedingProps {
    data: {
        allIndiaSpeechesMetadataJson: {
            nodes: {
                files: string[],
                speech: SpeechDAO
            }[]
        }
    };
}

const ProceedingsPage: React.FC<ProceedingProps> = ({data}) => {
    const eventsGroupedByStartDate: any[] = data.allIndiaSpeechesMetadataJson.nodes
        .map(node => ({files: node.files, speech: Speech.deserialize(node.speech, 'IST')}))
        .sort((nodeA, nodeB) => nodeA.speech.order - nodeB.speech.order)
        .filter(node => node.speech.speakers.length > 0)
        .reduce<any[]>((arr, node) => {
            if (arr[arr.length - 1]?.[0] !== node.speech.startDate) {
                arr.push([node.speech.startDate, []]);
            }

            node.speech.html = '<div class="soul-grid soul-grid--horizontal soul-grid--compact-gap">';

            if (node.files.length > 1) {
                for (const i in node.files) {
                    node.speech.html += `<div class="soul-grid-item-auto-span"><a href="/proceedings/india/${node.files[i]}" class="soul-button soul-button--primary soul-button--compact" download>Part ${parseInt(i) + 1}</a></div>`;
                }
            } else {
                node.speech.html += `<div class="soul-grid-item-auto-span"><a href="/proceedings/india/${node.files[0]}" class="soul-button soul-button--primary soul-button--compact" download>Download</a></div>`;
            }


            node.speech.html += '</div>';
            arr[arr.length - 1][1].push(node.speech);

            return arr;
        }, []);

    return (
        <IndiaBasePageLayout location="/india/proccedings">
            <Section>
                <h1 className="soul-heading soul-heading--h1">Proceedings</h1>
            </Section>
            <EventDaysList startDateEventsMap={eventsGroupedByStartDate} hideDateAndTime={true}>
                <a href="/proceedings/india/proceedings-india.zip" className="soul-button soul-button--primary" download>Download all</a>
            </EventDaysList>
        </IndiaBasePageLayout>
    );
};

export const pageQuery = graphql`
query IndiaProceedingsQuery {
  allIndiaSpeechesMetadataJson {
    nodes {
      files
      speech {
        childMarkdownRemark {
          html
          fields {
            slug
          }
          frontmatter {
            title
            startDateTime
            endDateTime
            speaker {
              childMarkdownRemark {
                html
                fields {
                  slug
                }
                frontmatter {
                  firstName
                  lastName
                  jobTitle
                  organization
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default ProceedingsPage;
